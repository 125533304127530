import React from "react";

export default function NavButton({ label, type }) {
  const styles = {
    lightDisabled: "flex items-center justify-center h-8 px-4 rounded-full border border-black text-black hover:line-through hover:bg-[#F5921D]",
    lightInactive: "flex items-center justify-center h-8 px-4 rounded-full border border-black text-black hover:bg-[#F5921D]",
    lightActive: "flex items-center justify-center h-8 px-4 rounded-full bg-black text-white text-white",
    darkDisabled: "flex items-center justify-center h-8 px-4 rounded-full border border-white text-white hover:line-through hover:bg-[#F5921D]",
    darkInactive: "flex items-center justify-center h-8 px-4 rounded-full border border-white text-white hover:bg-[#F5921D]",
    darkActive: "flex items-center justify-center h-8 px-4 rounded-full bg-white text-black",
  };
  return <button className={styles[type]}>{label}</button>;
}
