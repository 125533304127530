import React from "react";
import arrowRight from '../assets/icons/arrow-right-black.svg'

export default function ServicesListItem({ service }) {
  return (
    <div className="flex gap-2 p-2 border-b border-black hover:bg-neutral-500/10">
      <img src={arrowRight} />
      <span>{service}</span>
    </div>
  );
}
