import React from "react";
import Header from "../components/Header";

export default function Services() {
  return (
    <div className="w-full flex flex-col gap-32 px-8">
      <Header
        colorStyle={"light"}
        logoColor={"black"}
        workStyle={"lightInactive"}
        servicesStyle={"lightActive"}
        contactStyle={"lightInactive"}
      />
      <h1 className="text-5xl">Providing precision and skillful execution.</h1>
    </div>
  );
}
