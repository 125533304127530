import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Button from "./Button";

// assets
import copy from "../staticCopy.json";

export default function Hero() {
  return (
    <div className="flex flex-col gap-8 items-start sm:mb-40">
      <motion.h1
        className="md:text-7xl leading-8 selection:bg-[#F5921D]"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1, duration: 0.5 }}
      >
        {copy.home.hero}
      </motion.h1>
      <Link to={"contact"}>
        <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.5 }}>
        <Button
          label="Get in touch"
          buttonType={"lightButton"}
          iconType={"lightIcon"}
        />
        </motion.div>
      </Link>
    </div>
  );
}
