import React from 'react'
import { motion } from 'framer-motion'

import colslim from '../assets/images/projects/project.png'


export default function WorkGridItem({ img, title, type, date }) {
  return (
    <div className='flex flex-col gap-4'>
      <motion.img 
      src={img}
      className='hover:filter:shadow-lg'
      whileHover={{
        scale: 1.02,
        transition: { duration: 0.1 }
      }}
      />
      <div className='grid grid-cols-4 gap-4'>
        <h4 className='col-start-1 text-xl'>{title}</h4>
        <p className='col-start-3 text-xl'>{type}</p>
        <p className='col-start-4 text-xl'>{date}</p>
      </div>
    </div>
  )
}
