import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import Logo from "../assets/images/Logo";
import Button from "../components/Button";

export default function Soon() {
  return (
    <div className="flex flex-col items-center justify-center gap-32 m-auto p-auto pt-24">
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 1 }}
      >
        <Link to={'/'}>
            <Logo fill={"black"} />
        </Link>
      </motion.div>
      <motion.h1
        className="text-3xl"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 1 }}
      >
        soon.
      </motion.h1>
      <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 2, duration: 1 }}
      >
        <Link to={'/'}>
          <Button
            label="Back to home"
            buttonType={"lightButton"}
            iconType={"lightIcon"}
          />
        </Link>
      </motion.div>
    </div>
  );
}
