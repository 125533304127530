export default function Logo(props) {
  return (
    <svg width="158" height="63" viewBox="0 0 158 63" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M56.4285 17.0424H56.3248C55.0697 19.1477 52.9765 20.1333 50.5427 20.1333C47.7955 20.1333 45.5183 19.0933 43.9225 17.3807C42.2484 15.5884 41.3575 13.0691 41.3575 10.1065C41.3575 6.99038 42.405 4.2611 44.3141 2.44352C45.8845 0.911707 48.0305 0.0019474 50.8031 0.0019474C55.565 0.0019474 58.3376 2.52128 59.0445 6.15837H55.381C54.8582 4.36607 53.4445 3.01504 50.854 3.01504C46.9027 3.01504 45.0445 6.10589 45.0445 10.1065C45.0445 14.1071 47.3471 17.1202 50.854 17.1202C54.1239 17.1202 55.8509 14.9119 55.8783 12.4451V12.2118H51.1692V9.40669H59.3597V19.694H56.9259L56.4285 17.0444V17.0424Z" fill={props.fill}/>
        <path d="M60.6501 10.0793C60.6501 4.28637 64.339 0 70.2269 0C76.1147 0 79.8036 4.28637 79.8036 10.0793C79.8036 15.8722 76.1147 20.1313 70.2269 20.1313C64.339 20.1313 60.6501 15.845 60.6501 10.0793ZM76.0873 10.0793C76.0873 6.0534 74.0979 2.96255 70.2523 2.96255C66.4067 2.96255 64.339 6.0534 64.339 10.0793C64.339 14.1052 66.3793 17.093 70.2523 17.093C74.1253 17.093 76.0873 14.0799 76.0873 10.0793Z" fill={props.fill}/>
        <path d="M80.6535 10.0793C80.6535 4.28637 84.3424 0 90.2302 0C96.1181 0 99.807 4.28637 99.807 10.0793C99.807 15.8722 96.1181 20.1313 90.2302 20.1313C84.3424 20.1313 80.6535 15.845 80.6535 10.0793ZM96.0907 10.0793C96.0907 6.0534 94.1013 2.96255 90.2557 2.96255C86.4101 2.96255 84.3424 6.0534 84.3424 10.0793C84.3424 14.1052 86.3827 17.093 90.2557 17.093C94.1287 17.093 96.0907 14.0799 96.0907 10.0793Z" fill={props.fill}/>
        <path d="M101.53 0.365458H109.196C114.821 0.365458 118.04 4.26109 118.04 10.1318C118.04 16.0024 114.874 19.6901 108.988 19.6901H101.532V0.363512L101.53 0.365458ZM108.751 16.6264C112.597 16.6264 114.324 14.1849 114.324 10.1318C114.324 6.07867 112.675 3.42909 108.855 3.42909H105.166V16.6245H108.751V16.6264Z" fill={props.fill}/>
        <path d="M7.12701 22.4988H10.6828C10.7768 22.4988 10.863 22.5571 10.8943 22.6465L17.8199 41.524C17.8747 41.6698 17.7651 41.8253 17.6084 41.8253H14.221C14.1231 41.8253 14.0369 41.7631 14.0076 41.6718L12.5665 37.3815C12.5351 37.2901 12.449 37.2279 12.3511 37.2279H5.29821C5.20031 37.2279 5.1161 37.2901 5.08478 37.3796L3.61821 41.6718C3.58688 41.7631 3.50072 41.8234 3.40477 41.8234H0.226876C0.0702323 41.8234 -0.0394233 41.6679 0.0134439 41.5221L6.91359 22.6446C6.94492 22.5551 7.03107 22.4968 7.12701 22.4968M6.3438 34.5764H11.3133C11.468 34.5764 11.5757 34.4267 11.5267 34.2809L10.1346 30.1073C9.37484 27.8212 8.90492 25.4574 8.90492 25.4574H8.85205C8.85205 25.4574 8.19806 28.2625 7.56953 30.1073L6.12842 34.279C6.07751 34.4248 6.18715 34.5764 6.34184 34.5764" fill={props.fill}/>
        <path d="M19.0515 22.4988H32.4151C32.5404 22.4988 32.6403 22.5998 32.6403 22.7223V25.3641C32.6403 25.4885 32.5385 25.5877 32.4151 25.5877H22.6621C22.5368 25.5877 22.4369 25.6888 22.4369 25.8112V30.3231C22.4369 30.4475 22.5388 30.5466 22.6621 30.5466H30.7136C30.8389 30.5466 30.9388 30.6477 30.9388 30.7721V33.3109C30.9388 33.4353 30.8369 33.5345 30.7136 33.5345H22.6621C22.5368 33.5345 22.4369 33.6355 22.4369 33.758V41.5959C22.4369 41.7204 22.3351 41.8195 22.2118 41.8195H19.0534C18.9281 41.8195 18.8283 41.7184 18.8283 41.5959V22.7184C18.8283 22.594 18.9301 22.4949 19.0534 22.4949" fill={props.fill}/>
        <path d="M33.5018 22.4988H48.8529C48.9782 22.4988 49.078 22.5998 49.078 22.7223V25.3641C49.078 25.4885 48.9762 25.5877 48.8529 25.5877H43.2078C43.0825 25.5877 42.9827 25.6887 42.9827 25.8112V41.5979C42.9827 41.7223 42.8809 41.8214 42.7575 41.8214H39.5718C39.4464 41.8214 39.3466 41.7204 39.3466 41.5979V25.8112C39.3466 25.6868 39.2448 25.5877 39.1214 25.5877H33.5038C33.3785 25.5877 33.2786 25.4866 33.2786 25.3641V22.7223C33.2786 22.5979 33.3804 22.4988 33.5038 22.4988" fill={props.fill}/>
        <path d="M50.3547 22.4988H64.1374C64.2627 22.4988 64.3625 22.5998 64.3625 22.7223V25.3388C64.3625 25.4633 64.2607 25.5624 64.1374 25.5624H53.9653C53.84 25.5624 53.7402 25.6635 53.7402 25.7859V30.117C53.7402 30.2414 53.842 30.3425 53.9653 30.3425H62.9058C63.0311 30.3425 63.1309 30.4436 63.1309 30.5661V32.9999C63.1309 33.1243 63.0291 33.2254 62.9058 33.2254H53.9653C53.84 33.2254 53.7402 33.3265 53.7402 33.4489V38.4545C53.7402 38.579 53.842 38.6781 53.9653 38.6781H64.3723C64.4976 38.6781 64.5995 38.7792 64.5995 38.9017V41.5959C64.5995 41.7204 64.4976 41.8195 64.3723 41.8195H50.3547C50.2294 41.8195 50.1295 41.7184 50.1295 41.5959V22.7184C50.1295 22.594 50.2314 22.4949 50.3547 22.4949" fill={props.fill}/>
        <path d="M66.1835 22.7223C66.1835 22.5979 66.2853 22.4968 66.4087 22.4968H75.3667C79.0048 22.4968 81.6207 24.5496 81.6207 27.9262C81.6207 30.2376 80.5731 31.9015 78.0355 32.7063V32.7841C80.1032 33.4081 80.861 34.5764 81.0705 36.9655C81.3838 39.7978 81.4112 41.3821 81.934 41.6406V41.8234H78.3508C78.2881 41.8234 78.2294 41.8001 78.1863 41.7553C77.7732 41.3277 77.7614 39.8503 77.5636 37.4087C77.3541 35.1226 76.2811 34.1351 73.953 34.1351H70.0193C69.894 34.1351 69.7941 34.2362 69.7941 34.3587V41.5998C69.7941 41.7242 69.6923 41.8234 69.569 41.8234H66.4106C66.2853 41.8234 66.1855 41.7223 66.1855 41.5998V22.7223H66.1835ZM69.7941 31.157C69.7941 31.2814 69.8959 31.3806 70.0193 31.3806H74.5032C76.7804 31.3806 77.9827 30.2375 77.9827 28.4452C77.9827 26.5227 76.9625 25.4322 74.6344 25.4322H70.0193C69.894 25.4322 69.7941 25.5332 69.7941 25.6557V31.1551V31.157Z" fill={props.fill}/>
        <path d="M83.8607 22.4988H87.2266C87.3049 22.4988 87.3754 22.5376 87.4185 22.6037L93.8154 32.6305C94.863 34.3198 96.2493 37.1249 96.2493 37.1249H96.3276C96.3276 37.1249 96.1181 34.1118 96.1181 32.1893V22.7243C96.1181 22.5998 96.2199 22.5007 96.3432 22.5007H99.3195C99.4448 22.5007 99.5447 22.6018 99.5447 22.7243V41.6018C99.5447 41.7262 99.4428 41.8253 99.3195 41.8253H95.9536C95.8753 41.8253 95.8048 41.7864 95.7637 41.7204L89.3942 31.746C88.3212 30.0315 86.9349 27.2264 86.9349 27.2264H86.882C86.882 27.2264 87.0641 30.2395 87.0641 32.162V41.5998C87.0641 41.7242 86.9623 41.8234 86.8389 41.8234H83.8627C83.7374 41.8234 83.6375 41.7223 83.6375 41.5998V22.7223C83.6375 22.5979 83.7393 22.4988 83.8627 22.4988" fill={props.fill}/>
        <path d="M101.227 32.2126C101.227 26.4197 104.916 22.1333 110.803 22.1333C116.691 22.1333 120.38 26.4197 120.38 32.2126C120.38 38.0055 116.691 42.2647 110.803 42.2647C104.916 42.2647 101.227 37.9783 101.227 32.2126ZM116.664 32.2126C116.664 28.1867 114.674 25.0959 110.829 25.0959C106.983 25.0959 104.916 28.1867 104.916 32.2126C104.916 36.2385 106.956 39.2263 110.829 39.2263C114.702 39.2263 116.664 36.2132 116.664 32.2126Z" fill={props.fill}/>
        <path d="M121.23 32.2126C121.23 26.4197 124.919 22.1333 130.807 22.1333C136.695 22.1333 140.384 26.4197 140.384 32.2126C140.384 38.0055 136.695 42.2647 130.807 42.2647C124.919 42.2647 121.23 37.9783 121.23 32.2126ZM136.667 32.2126C136.667 28.1867 134.678 25.0959 130.832 25.0959C126.987 25.0959 124.919 28.1867 124.919 32.2126C124.919 36.2385 126.959 39.2263 130.832 39.2263C134.705 39.2263 136.667 36.2132 136.667 32.2126Z" fill={props.fill}/>
        <path d="M142.318 22.4988H145.684C145.762 22.4988 145.833 22.5376 145.874 22.6037L152.271 32.6305C153.318 34.3198 154.705 37.1249 154.705 37.1249H154.783C154.783 37.1249 154.573 34.1118 154.573 32.1893V22.7243C154.573 22.5998 154.675 22.5007 154.799 22.5007H157.775C157.9 22.5007 158 22.6018 158 22.7243V41.6018C158 41.7262 157.898 41.8253 157.775 41.8253H154.409C154.331 41.8253 154.26 41.7864 154.219 41.7204L147.85 31.746C146.777 30.0315 145.39 27.2264 145.39 27.2264H145.337C145.337 27.2264 145.519 30.2395 145.519 32.162V41.5998C145.519 41.7242 145.418 41.8234 145.294 41.8234H142.318C142.193 41.8234 142.093 41.7223 142.093 41.5998V22.7223C142.093 22.5979 142.195 22.4988 142.318 22.4988Z" fill={props.fill}/>
        <path d="M49.4266 57.6797H48.8117C48.6747 57.6797 48.5533 57.7672 48.5083 57.8955C47.7113 60.1369 46.1566 61.3693 44.1301 61.3693C42.454 61.3693 41.1852 60.6345 40.2551 59.1202C39.3329 57.602 38.8649 55.5997 38.8649 53.1717C38.8649 50.9304 39.2545 49.2567 40.0221 48.1933C40.7759 47.1514 41.8235 46.6226 43.1354 46.6226C45.1678 46.6226 46.7029 47.857 47.8288 50.3978C47.8817 50.5163 47.9972 50.5922 48.1245 50.5922H49.0487C49.1564 50.5922 49.2562 50.5397 49.3169 50.4522C49.3776 50.3667 49.3894 50.2558 49.3522 50.1587C48.9566 49.1303 48.6257 47.8143 48.3653 46.2455C48.3516 46.16 48.3027 46.0822 48.2322 46.0317C48.1617 45.9811 48.0775 45.9637 47.9933 45.9773C47.6878 46.0317 47.4078 46.0589 47.1611 46.0589C46.6657 46.0589 46.045 45.9656 45.3206 45.7829C44.5452 45.5865 43.8364 45.4874 43.2137 45.4874C40.913 45.4874 39.0157 46.2922 37.5726 47.8784C36.1315 49.4491 35.4011 51.4941 35.4011 53.9552C35.4011 56.4162 36.153 58.5448 37.6372 60.1718C39.1194 61.7834 41.0736 62.5998 43.4448 62.5998C45.254 62.5998 46.6971 62.1352 47.7387 61.2177C48.7001 60.3604 49.3737 59.3068 49.7438 58.086C49.7731 57.9888 49.7536 57.8877 49.6929 57.8061C49.6322 57.7244 49.5343 57.6758 49.4305 57.6758" fill={props.fill}/>
        <path d="M60.8224 50.5863C60.8224 49.4647 59.9021 48.7396 58.4766 48.7396C56.6987 48.7396 55.3281 49.8885 54.4 52.157L54.9208 49.5288C54.9326 49.4666 54.9287 49.4025 54.9052 49.3461C54.8033 49.0914 54.6271 48.9048 54.3843 48.7921C54.2923 48.7493 54.1807 48.7571 54.0887 48.8135C53.0254 49.4647 51.7938 49.9273 50.4291 50.1898C50.2764 50.2189 50.1667 50.3511 50.1667 50.5027V50.804C50.1667 50.8935 50.2039 50.979 50.2705 51.0393C50.3371 51.0995 50.4271 51.1306 50.5172 51.1248C50.9675 51.0898 51.2808 51.1578 51.4727 51.3367C51.6509 51.5 51.7371 51.7643 51.7371 52.1473V60.4537C51.7371 60.8892 51.3024 61.173 50.4467 61.2954C50.2842 61.3188 50.1647 61.4529 50.1647 61.6142V61.9058C50.1647 61.9952 50.2 62.0769 50.2646 62.1372C50.3292 62.1974 50.4134 62.2285 50.5035 62.2246C52.393 62.1391 54.3412 62.1391 56.2954 62.2246C56.3012 62.2246 56.3052 62.2246 56.311 62.2246C56.3952 62.2246 56.4736 62.1935 56.5343 62.1352C56.5989 62.075 56.6341 61.9914 56.6341 61.9039V61.6104C56.6341 61.449 56.5166 61.3168 56.3561 61.2915C55.6962 61.1924 54.911 60.9669 54.911 60.4498V53.2806C54.911 53.2806 54.911 53.2631 54.913 53.2534C54.9913 52.8024 55.2126 52.2775 55.5729 51.7002C55.941 51.117 56.3933 50.8332 56.9591 50.8332C57.294 50.8332 57.6699 51.0198 58.1085 51.4047C58.5765 51.8168 59.019 52.0248 59.4243 52.0248C60.2487 52.0248 60.8263 51.4319 60.8263 50.5824" fill={props.fill}/>
        <path d="M66.3519 48.8037C64.6876 48.8037 63.2347 49.4238 62.0285 50.6466C60.84 51.8635 60.2389 53.5547 60.2389 55.6736C60.2389 57.7925 60.8498 59.4779 62.0559 60.7006C63.266 61.9156 64.8011 62.5298 66.6221 62.5298C68.772 62.5182 70.315 61.6473 71.2098 59.9444C71.2627 59.8433 71.2607 59.7228 71.2 59.6237C71.1413 59.5265 71.0375 59.4681 70.9239 59.4681H70.5558C70.4344 59.4681 70.3228 59.5362 70.268 59.647C69.6551 60.858 68.6487 61.4723 67.2741 61.4723C66.0719 61.4723 65.1751 60.9689 64.5348 59.9327C63.9278 58.953 63.6067 57.6467 63.5793 56.0507C63.5793 56.0157 63.591 55.9846 63.6165 55.9593C63.642 55.9341 63.6733 55.9205 63.7085 55.9205H70.2621C71.294 55.9205 71.715 55.4034 71.715 54.1359C71.715 52.5769 71.198 51.2861 70.1779 50.3006C69.1499 49.3072 67.8635 48.8018 66.3538 48.8018M64.3077 51.1753C64.7913 50.2636 65.4532 49.8185 66.3284 49.8185C67.8655 49.8185 68.6604 51.4922 68.6957 54.7949C68.6957 54.8299 68.682 54.861 68.6585 54.8863C68.635 54.9116 68.6017 54.9252 68.5684 54.9252H63.7105C63.6752 54.9252 63.6439 54.9116 63.6185 54.8863C63.595 54.861 63.5813 54.8299 63.5813 54.7949C63.6028 53.2495 63.8475 52.0326 64.3096 51.1734" fill={props.fill}/>
        <path d="M84.499 61.0408C83.5709 60.9572 83.193 60.514 83.193 59.5148V52.9463C83.193 51.4047 82.8738 50.3181 82.2453 49.7193C81.6324 49.1109 80.6005 48.8037 79.1751 48.8037C77.3874 48.8037 75.9502 49.1361 74.7832 49.8185C73.6064 50.493 73.0092 51.3367 73.0092 52.3242C73.0092 53.2476 73.4968 53.7141 74.4621 53.7141C75.0632 53.7141 75.492 53.4886 75.7348 53.0435C75.9776 52.6255 76.1166 52.1706 76.1558 51.6574C76.193 51.1889 76.3731 50.7613 76.7079 50.355C77.033 49.9604 77.546 49.7699 78.2803 49.7699C79.0145 49.7699 79.4766 49.9818 79.7018 50.4153C79.9348 50.8526 80.0484 51.6633 80.0484 52.8938V53.9066C80.0484 53.9649 80.0053 54.0174 79.9466 54.031C77.08 54.6647 75.0573 55.3723 73.9334 56.1362C72.7782 56.9235 72.1927 57.9363 72.1927 59.1455C72.1927 60.3546 72.5706 61.1749 73.3186 61.7348C74.0705 62.2849 74.9261 62.5532 75.9306 62.5532C76.9351 62.5532 77.8965 62.283 78.6464 61.7484C79.3454 61.2371 79.8741 60.6889 80.2207 60.1194L80.1052 61.8222C80.1012 61.8767 80.111 61.9311 80.1326 61.9778C80.25 62.2383 80.44 62.4229 80.6984 62.5221C80.7572 62.5454 80.8238 62.5493 80.8903 62.5337C82.026 62.2596 83.2361 62.0866 84.4872 62.0186C84.6596 62.0089 84.7947 61.8689 84.7947 61.6998V61.3615C84.7947 61.1963 84.6674 61.0563 84.5029 61.0427M80.0503 55.0729V58.7683C80.0503 58.7878 80.0464 58.8072 80.0386 58.8247C79.8193 59.3068 79.4551 59.8025 78.9558 60.2982C78.4486 60.8017 77.871 61.0563 77.2366 61.0563C76.1401 61.0563 75.4313 60.2185 75.4313 58.9219C75.4313 57.046 76.9312 55.7105 79.8898 54.9524C79.8996 54.9505 79.9113 54.9485 79.9211 54.9485C79.9485 54.9485 79.9759 54.9582 79.9994 54.9757C80.0307 55.001 80.0503 55.0379 80.0503 55.0768" fill={props.fill}/>
        <path d="M91.6713 49.0312H89.1474C89.0769 49.0312 89.0201 48.9748 89.0201 48.9048V45.8159C89.0201 45.639 88.8752 45.4952 88.6971 45.4952H88.1664C88.0098 45.4952 87.8766 45.606 87.8492 45.7615C87.6436 46.8676 87.2931 47.6976 86.8075 48.2283C86.3357 48.7318 85.6699 48.9942 84.7712 49.0292C84.595 49.037 84.4559 49.177 84.4559 49.35V49.7932C84.4559 49.9701 84.6008 50.1139 84.779 50.1139H85.7443C85.8148 50.1139 85.8716 50.1703 85.8716 50.2403V58.7411C85.8716 61.1108 87.0778 62.4151 89.2649 62.4151C89.2864 62.4151 89.3099 62.4151 89.3315 62.4151C90.426 62.4151 91.2895 62.1177 91.8985 61.5287C91.9611 61.4685 91.9964 61.3849 91.9964 61.2954V60.9727C91.9964 60.8522 91.9298 60.7434 91.8221 60.6889C91.7164 60.6326 91.5891 60.6423 91.4912 60.7084C91.1113 60.9708 90.6943 61.103 90.2498 61.103C89.9287 61.1069 89.6663 61.0155 89.4724 60.8269C89.1729 60.5334 89.0201 59.9969 89.0201 59.2349V50.2403C89.0201 50.1703 89.0769 50.1139 89.1474 50.1139H91.6713C91.8495 50.1139 91.9944 49.9701 91.9944 49.7932V49.3539C91.9944 49.177 91.8495 49.0331 91.6713 49.0331" fill={props.fill}/>
        <path d="M98.64 61.2935C97.7648 61.1613 97.3222 60.8775 97.3222 60.4518V52.9443C97.334 51.2356 97.3849 50.1178 97.475 49.5269C97.4848 49.4588 97.475 49.3908 97.4436 49.3325C97.3164 49.0875 97.1421 48.9068 96.9267 48.794C96.8347 48.7474 96.7231 48.7532 96.6271 48.8076C95.5228 49.4511 94.2657 49.9157 92.8912 50.1859C92.7404 50.215 92.6288 50.3472 92.6288 50.4988V50.804C92.6288 50.8915 92.666 50.977 92.7306 51.0373C92.7952 51.0976 92.8834 51.1287 92.9715 51.1228C93.3807 51.0976 93.6862 51.1773 93.88 51.3561C94.0582 51.5233 94.1502 51.7877 94.1502 52.1395V60.4459C94.1502 60.8814 93.7155 61.1652 92.8599 61.2877C92.6974 61.311 92.5779 61.4451 92.5779 61.6065V61.8981C92.5779 61.9855 92.6131 62.0691 92.6778 62.1294C92.7385 62.1877 92.8168 62.2188 92.901 62.2188C92.9069 62.2188 92.9108 62.2188 92.9166 62.2188C94.8003 62.1333 96.7055 62.1333 98.5813 62.2188C98.6694 62.2207 98.7555 62.1916 98.8201 62.1313C98.8848 62.0711 98.92 61.9875 98.92 61.9V61.6065C98.92 61.4471 98.8025 61.3129 98.642 61.2877" fill={props.fill}/>
        <path d="M95.652 47.5791C96.1259 47.5791 96.5233 47.4158 96.8327 47.0931C97.1577 46.7723 97.3203 46.3777 97.3203 45.9209C97.3203 45.4641 97.1558 45.0714 96.8327 44.7506C96.5214 44.4279 96.1239 44.2646 95.6501 44.2646C95.1762 44.2646 94.7944 44.4279 94.4674 44.7506C94.1522 45.0772 93.9936 45.4699 93.9936 45.9209C93.9936 46.3719 94.1541 46.7665 94.4694 47.0931C94.7924 47.4158 95.1899 47.5791 95.6501 47.5791" fill={props.fill}/>
        <path d="M111.902 49.9973C112.064 49.9779 112.186 49.8399 112.186 49.6785V49.3519C112.186 49.175 112.041 49.0312 111.863 49.0312H106.899C106.721 49.0312 106.576 49.175 106.576 49.3519V49.6649C106.576 49.834 106.709 49.9759 106.879 49.9857L107.455 50.0226C107.988 50.079 108.348 50.2384 108.522 50.5008C108.681 50.736 108.696 51.0665 108.571 51.4825L106.376 58.4962C106.357 58.5603 106.3 58.5856 106.255 58.5856C106.212 58.5914 106.153 58.5642 106.131 58.5001L103.711 51.43C103.527 50.8818 103.51 50.4891 103.656 50.2617C103.747 50.1237 103.899 50.0459 104.124 50.0226L104.518 49.9895C104.686 49.9759 104.816 49.8379 104.816 49.6688V49.3519C104.816 49.175 104.671 49.0312 104.491 49.0312H98.875C98.6968 49.0312 98.5499 49.175 98.5499 49.3519V49.6727C98.5499 49.8379 98.6752 49.9759 98.8417 49.9915L99.1373 50.0206C99.9088 50.0984 100.271 50.4561 100.625 51.4903L104.391 62.0944C104.436 62.2227 104.559 62.3082 104.696 62.3082H105.981C106.122 62.3082 106.245 62.2188 106.29 62.0847L109.701 51.5388C110.006 50.6388 110.676 50.1276 111.69 50.0187L111.902 49.9934V49.9973Z" fill={props.fill}/>
        <path d="M114.502 55.9205H121.056C122.088 55.9205 122.507 55.4034 122.507 54.1359C122.507 52.5769 121.99 51.2861 120.969 50.3006C119.942 49.3053 118.655 48.8018 117.145 48.8018C115.483 48.8018 114.028 49.4219 112.822 50.6446C111.634 51.8635 111.032 53.5528 111.032 55.6716C111.032 57.7905 111.643 59.4759 112.849 60.6987C114.06 61.9117 115.597 62.5279 117.416 62.5279C119.566 62.5162 121.109 61.6453 122.003 59.9425C122.056 59.8414 122.052 59.7208 121.994 59.6237C121.935 59.5265 121.831 59.4681 121.717 59.4681H121.349C121.228 59.4681 121.116 59.5362 121.062 59.647C120.449 60.858 119.442 61.4723 118.068 61.4723C116.865 61.4723 115.969 60.9689 115.328 59.9327C114.723 58.953 114.402 57.6486 114.375 56.0507C114.375 56.0177 114.387 55.9846 114.412 55.9593C114.437 55.9341 114.469 55.9205 114.504 55.9205M114.375 54.7969C114.396 53.2515 114.641 52.0346 115.103 51.1753C115.587 50.2636 116.249 49.8185 117.124 49.8185C118.661 49.8185 119.456 51.4922 119.489 54.7949C119.489 54.8299 119.475 54.861 119.452 54.8863C119.427 54.9116 119.395 54.9252 119.36 54.9252H114.502C114.467 54.9252 114.436 54.9116 114.41 54.8863C114.387 54.861 114.373 54.8299 114.373 54.7949" fill={props.fill}/>
    </svg>
  )
}
