import { motion } from "framer-motion";

// components
import Header from "../components/Header";
import Button from "../components/Button";


export default function Contact() {

  const styles = {
    body: "w-full h-full sm:h-screen flex flex-col px-4 sm:px-8 bg-black text-white relative overflow-hidden sm:overflow-automatic",
    inputWrapper: "flex flex-col gap-4",
    inputSubtitle: "text-2xl",
    input: "w-80 sm:w-full h-10 rounded-sm pl-2 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:bg-black focus:border-transparent border border-neutral-500 text-black focus:text-white",
    textArea: "w-80 sm:w-full h-24 rounded-sm pl-2 pt-1 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:bg-black focus:border-transparent border-2 border-neutral-500 text-black focus:text-white",
  };

  return (
    <motion.div 
    className={styles.body}
    initial={{ y: 5000 }}
    animate={{  y: 0 }}
    transition={{ duration: 2 }}
    >

      <Header
        colorStyle={"dark"}
        logoColor={"white"}
        workStyle={"darkDisabled"}
        servicesStyle={"darkDisabled"}
        contactStyle={"darkActive"}
      />
      <div className="flex flex-col gap-8 sm:gap-12 mb-24 sm:mt-24">
        <div className="flex flex-col">
          <h1 className="text-2xl sm:text-5xl text-neutral-500">You want to talk to us?</h1>
          <h2 className="text-2xl sm:text-5xl">Shoot us an email.</h2>
        </div>
        <form action="https://formcarry.com/s/s3aOU0ZmpE" method="POST" acceptCharset="UTF-8" className="flex flex-col gap-6 w-[512px]">
          <div className={styles.inputWrapper}>
            <h3 className={styles.inputSubtitle}>Name</h3>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              className={styles.input}
            />
          </div>
          <div className={styles.inputWrapper}>
            <h3 className={styles.inputSubtitle}>Email</h3>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              className={styles.input}
            />
          </div>
          <div className={styles.inputWrapper}>
            <h3 className={styles.inputSubtitle}>Subject</h3>
            <input
              type="text"
              name="subject"
              id="subject"
              placeholder="Subject"
              className={styles.input}
            />
          </div>
          <div className={styles.inputWrapper}>
            <h3 className={styles.inputSubtitle}>Message</h3>
            <textarea
              name="message"
              id="message"
              cols="30"
              rows="10"
              placeholder="Enter your message..."
              className={styles.textArea}
            ></textarea>
          </div>
          <input type="hidden" name="_gotcha"></input>
          <button
          type="submit">
            <Button
              label={"Shoot"}
              buttonType={"darkButton"}
              iconType={"darkIcon"}
            />
          </button>
        </form>
      </div>
    </motion.div>
  );
}
