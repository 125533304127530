import React from "react";
import { motion } from "framer-motion";
import ServicesListItem from "./ServicesListItem";

export default function ServicesList() {
  return (
    <div className="flex flex-col gap-4 sm:gap-10 selection:bg-[#F5921D]">
      <motion.h3 
      className="text-2xl sm:text-5xl"
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 1, duration: 0.5 }}
      >
        Providing precision and skillful execution.</motion.h3>
      <motion.div
        className="flex flex-col gap-4"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1.5, duration: 0.5 }}
      >
        <ServicesListItem service={"Corporate Identity"} />
        <ServicesListItem service={"Brand Communication & Strategy"} />
        <ServicesListItem service={"360° Campaign"} />
        <ServicesListItem service={"Social Media Strategy & Content"} />
        <ServicesListItem
          service={"Photography, Video Production, Post-Production"}
        />
        <ServicesListItem service={"Key Visual ve Poster Design"} />
      </motion.div>
    </div>
  );
}
