import React from "react";
import { motion } from "framer-motion";

import arrow_right from "../assets/icons/arrow-right-black.svg";

export default function Button({ label, buttonType, iconType }) {
  const styles = {
    lightButton: "rounded-full border border-black pl-4 h-10 flex gap-4 items-center text-black transition hover:bg-[#F5921D]",
    lightIcon: "w-10 h-10 rounded-full border-l border-t border-b border-black flex items-center justify-center",
    darkButton: "rounded-full border border-white pl-4 h-10 flex gap-4 items-center text-white transition hover:bg-[#F5921D]",
    darkIcon: "w-10 h-10 rounded-full bg-white flex items-center justify-center",
  };
  return (
    <motion.button className={styles[buttonType]}>
      {label}
      <div className={styles[iconType]}>
        <img src={arrow_right} alt="arrow icon"/>
      </div>
    </motion.button>
  );
}
