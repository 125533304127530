import React from "react";
import WorksListItem from "./WorksListItem";
import { Link } from "react-router-dom";

export default function WorksList() {
  return (
    <div className="w-full flex justify-start flex-col gap-6">
      <h3 className="text-2xl">Works</h3>
      <Link to="/soon">
        <WorksListItem
          projectTitle={"Colslim"}
          projectType={"Branding"}
          protjectDate={"2023"}
        />
      </Link>
      <Link to="/soon">
        <WorksListItem
          projectTitle={"Step İstanbul"}
          projectType={"Branding"}
          protjectDate={"2023"}
        />
      </Link>
      <Link to="/soon">
        <WorksListItem
          projectTitle={"Inharmony"}
          projectType={"Branding"}
          protjectDate={"2023"}
        />
      </Link>
      <Link to="/soon">
        <WorksListItem
          projectTitle={"Tomtom Design Weeks"}
          projectType={"Branding"}
          protjectDate={"2023"}
        />
      </Link>
    </div>
  );
}
