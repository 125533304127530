import React from "react";
import { motion } from "framer-motion";

// assets
import handLeft from "../assets/images/handLeft.svg";
import handRight from "../assets/images/handRight.svg";
import copy from "../staticCopy.json";

export default function Values() {
  return (
    <div className="flex flex-col sm:gap-40 gap-8 my-16 selection:bg-[#F5921D]">
      <div className="w-full flex gap-4 items-start">
        <motion.h1
          className="hidden sm:block text-[96px] font-extralight"
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.1, duration: 0.5 }}
        >
          Good People
        </motion.h1>
        <motion.h1
          className="hidden sm:block text-[96px] font-extralight"
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          Good Work
        </motion.h1>
      </div>
      <div className="sm:grid grid-cols-8 gap-4">
        <div className="flex gap-10 col-start-4 col-span-5">
          <motion.img
            className="absolute left-[-40px] hidden sm:block mix-blend-color-dodge"
            src={handLeft}
            alt="left hand"
            initial={{ opacity: 0, x: -500 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.6, duration: 0.5 }}
          />
          <motion.h2
            className="text-2xl sm:text-5xl font-medium selection:bg-[#F5921D]"
            initial={{ opacity: 0, x: 200 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 1, duration: 0.5 }}
          >
            <span className="font-light">{copy.home.value1_SPAN}</span>
            {copy.home.value1_H2}
          </motion.h2>
        </div>
      </div>
      <div className="sm:grid grid-cols-8 gap-4">
        <motion.img
          className="absolute sm:right-[-40px] hidden sm:block mix-blend-color-dodge"
          src={handRight}
          alt="left hand"
          initial={{ opacity: 0, x: 500 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.6, duration: 0.5 }}
        />
        <motion.h2
          className="text-2xl sm:text-5xl font-medium col-start-1 col-end-5 selection:bg-[#F5921D]"
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 1, duration: 0.5 }}
        >
          <span className="font-light">{copy.home.value2_SPAN}</span>
          {copy.home.value2_H2}
        </motion.h2>
      </div>
    </div>
  );
}
