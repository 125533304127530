// packages
import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

// assets
import Logo from "../assets/images/Logo";

// components
import NavButton from "./NavButton";

export default function Header({
  colorStyle,
  logoColor,
  workStyle,
  servicesStyle,
  contactStyle,
}) {
  const styles = {
    light: "flex flex-col sm:grid grid-cols-8 gap-4 py-8 items-center",
    dark: "flex flex-col sm:grid grid-cols-8 gap-4 text-white py-8 items-center",
  };

  return (
    <motion.header
      className={styles[colorStyle]}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5, duration: 0.5 }}
    >
      <Link to={"/"}>
        <Logo className="col-start-1" fill={logoColor} />
      </Link>

      {/* // default behaviour */}
      <div className="col-start-8 justify-self-end flex gap-4">
        <Link to={"/soon"}>
          <NavButton label={"Work"} type={workStyle} />
        </Link>
        <Link to={"/soon"}>
          <NavButton label={"Services"} type={servicesStyle} />
        </Link>
        <Link to={"/contact"}>
          <NavButton label={"Contact"} type={contactStyle} />
        </Link>
      </div>
    </motion.header>
  );
}
