import React from "react";
import { Link } from "react-router-dom";
// components
import Button from "./Button";
import LiveClock from "./Clock";

// assets
import arrow_right from "../assets/icons/arrow-right.svg";

export default function Footer() {
  return (
    <div className="bg-black text-white flex flex-col gap-32 pt-16 pb-8 rounded-t-[64px] overflow-hidden mt-64">
      <div className="flex flex-col gap-4 items-center z-50">
        <h2 className="sm:text-6xl text-neutral-500">Got something in mind?</h2>
        <h2 className="sm:text-6xl">Let's talk.</h2>
        <Link to={"/contact"}>
          <Button
            label={"Get in touch"}
            buttonType={"darkButton"}
            iconType={"darkIcon"}
          />
        </Link>
      </div>

      <div className="sm:grid grid-cols-8 gap-4 px-8 text-neutral-500 z-50">
        <div className="col-start-1 col-span-2">
          © Good Afternoon Creative, 2023
        </div>
        <div className="col-start-3 flex flex-col gap-1">
          <span>IST</span>
          <LiveClock />
        </div>

        <div className="flex flex-col gap-1 col-start-5">
          <a
            href="https://www.linkedin.com/company/good-afternoon-creative/"
            className="flex gap-1 transition hover:text-[#F5921D]"
          >
            <img src={arrow_right} alt="" />
            <span>Linkedin</span>
          </a>
          <a
            href="https://www.instagram.com/goodafternooncreative/"
            className="flex gap-1 transition hover:text-[#F5921D]"
          >
            <img src={arrow_right} alt="" />
            <span>Instagram</span>
          </a>
        </div>
        <div className="flex flex-col gap-1 col-start-8 items-end">
          <span className="flex gap-1">
            Enquiries
          </span>
          <a
            href="mailto:info@goodafternooncreative.com"
            className="flex gap-1 transition hover:text-[#F5921D]"
          >
            info@goodafternooncreative.com
          </a>
        </div>
      </div>
    </div>
  );
}
