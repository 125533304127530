import React from "react";

// components
import Header from "../components/Header";
import Footer from "../components/Footer";
import WorksListItem from "../components/WorksListItem";
import WorkGridItem from "../components/WorkGridItem";
import colslim from "../assets/images/projects/project.png";

export default function Works() {
  return (
    <div>
      <div className="w-full flex flex-col gap-32 px-8">
        <Header
          colorStyle={"light"}
          logoColor={"black"}
          workStyle={"lightActive"}
          servicesStyle={"lightInactive"}
          contactStyle={"lightInactive"}
        />

        <div className="flex flex-col gap-10">
          <h1 className="text-5xl">Featured Works</h1>
          {/* grid  */}
          <div className="grid grid-cols-8 gap-4">
            <div className="col-start-1 col-end-5">
              <WorkGridItem
                img={colslim}
                title={"Colslim"}
                type={"Branding"}
                date={"2023"}
              />
            </div>
            <div className="col-start-5 col-end-9">
              <WorkGridItem
                img={colslim}
                title={"Colslim"}
                type={"Branding"}
                date={"2023"}
              />
            </div>
            <div className="col-start-1 col-end-5">
              <WorkGridItem
                img={colslim}
                title={"Colslim"}
                type={"Branding"}
                date={"2023"}
              />
            </div>
            <div className="col-start-5 col-end-9">
              <WorkGridItem
                img={colslim}
                title={"Colslim"}
                type={"Branding"}
                date={"2023"}
              />
            </div>
          </div>
        </div>

        {/* list */}
        <div className="w-full flex justify-start flex-col gap-6">
          <h3 className="text-2xl">All Works</h3>
          <WorksListItem
            projectTitle={"Colslim"}
            projectType={"Branding"}
            protjectDate={"2023"}
          />
          <WorksListItem
            projectTitle={"Step İstanbul"}
            projectType={"Branding"}
            protjectDate={"2023"}
          />
          <WorksListItem
            projectTitle={"Inharmony"}
            projectType={"Branding"}
            protjectDate={"2023"}
          />
          <WorksListItem
            projectTitle={"Tomtom Design Weeks"}
            projectType={"Branding"}
            protjectDate={"2023"}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
